'use client';

import React, { useMemo } from 'react';
import Link from 'next/link';
import { CMS } from '@/types/CMS';
import clsx from 'clsx';
import Image from 'next/image';

interface IFullWidthImageProps {
    anchor?: string;
    data: CMS.Section.SectionsFullWidthImageComponent;
    defer?: boolean;
}

const FullWidthImage = (props: IFullWidthImageProps) => {
    const { anchor, data: { image, mobileImage = null, Link: linkHref, text }, defer } = props;

    const content = useMemo(() => {
        return (
            <div className="relative">
                <div className={clsx('md:block', { hidden: !!mobileImage })}>
                    {image?.data?.attributes.mime?.startsWith('video') && <video id={anchor} preload="auto" autoPlay playsInline controls={false} loop muted src={image.data?.attributes.url} className="w-full h-full" width={image.data.attributes.width} height={image.data.attributes.height} />}
                    {image?.data?.attributes.mime?.startsWith('image') && <Image id={anchor} alt="" priority={!defer} className="w-full h-full" src={image.data?.attributes.url} width={image.data.attributes.width} height={image.data.attributes.height} />}
                </div>
                {mobileImage && (
                    <div className="block md:hidden">
                        {mobileImage?.data?.attributes.mime?.startsWith('video') && <video id={anchor} preload="auto" autoPlay playsInline controls={false} loop muted src={mobileImage.data?.attributes.url} className="w-full h-full" width={mobileImage.data.attributes.width} height={mobileImage.data.attributes.height} />}
                        {mobileImage?.data?.attributes.mime?.startsWith('image') && <Image id={anchor} alt="" priority={!defer} className="w-full h-full" src={mobileImage.data?.attributes.url} width={mobileImage.data.attributes.width} height={mobileImage.data.attributes.height} />}
                    </div>
                )}
                {text && (
                    <div className="absolute inset-0">
                        <div className="text-center flex items-center justify-center h-full">
                            <div dangerouslySetInnerHTML={{ __html: text }} />
                        </div>
                    </div>
                )}
            </div>
        );
    }, [anchor, image, mobileImage, text, defer]);

    if (linkHref && image) {
        return (
            <Link id={anchor} href={linkHref} prefetch={false} className="no-hover">
                {content}
            </Link>
        );
    }
    return image ? content : null;
};

export default FullWidthImage;
