'use client';

import React, { useMemo } from 'react';
import { Item } from '@/api';
import Link from 'next/link';

import Container from '../Layout/Container';
import ProductTile from './ProductTile/ProductTile';

import { Carousel } from './carousel/carousel';

const responsive = {
    superLargeDesktop: {
        breakpoint: { min: 1440 },
        items: 4
    },
    desktop: {
        breakpoint: { max: 1440, min: 1080 },
        items: 4
    },
    tablet: {
        breakpoint: { max: 1080, min: 720 },
        items: 3
    },
    smallTablet: {
        breakpoint: { max: 720, min: 464 },
        items: 2
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 2
    }
};

const ItemsCarousel = (props: { anchor?: string; title?: string, items: Item[], link?: string }) => {
    const { anchor, items, title, link } = props;

    const renderedTitle = useMemo(() => {
        const titleElem = <h3 id={anchor} className="font-title text-charcoal mb-[30px] md:smb-12 text-center w-full">{title}</h3>;
        if (link && title) {
            return <Link href={link} prefetch={false}>{titleElem}</Link>;
        }
        if (title) {
            return titleElem;
        }
        return null;
    }, [title, link, anchor]);

    return (
        <Container className="my-10">
            {renderedTitle}
            <div className="mx-auto relative">
                <Carousel
                    breakpoints={responsive}
                    showDots
                    showArrows
                    autoPlay
                    pauseOnHover
                    arrowClass={{ left: 'w-8', right: 'w-8' }}
                >
                    {items.map((item) => <ProductTile key={item.id} data={item} />)}
                </Carousel>
            </div>
        </Container>
    );
};

export default ItemsCarousel;
