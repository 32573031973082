import clsx from 'clsx';
import React, { useEffect, useRef } from 'react';

export type ITextInputProps = {
    autoFocus?: boolean
    readOnly?: boolean
    type: 'text' | 'password';
    direction?: 'left' | 'center' | 'right';
    placeholder?: string;
    inputPlaceholder?: string;
    dark?: boolean;
    errored?: boolean | '';
    autoComplete?: string
    value?: string;
    defaultValue?: string;
    name?: string;
    className?: string;
    wrapperClassName?: string;
    relativeClassName?: string;
    onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined;
    icon?: JSX.Element;
};

const TextInput = (props: ITextInputProps) => {
    const {
        autoFocus,
        readOnly,
        type,
        placeholder,
        inputPlaceholder,
        dark = false,
        value,
        defaultValue,
        onChange,
        autoComplete,
        name,
        errored,
        className,
        direction = 'left',
        icon,
        wrapperClassName,
        relativeClassName
    } = props;

    const ref = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (autoFocus) {
            ref.current?.select();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={clsx('font-secondary text-left', wrapperClassName && wrapperClassName)}>
            {placeholder !== undefined && <label htmlFor={name} className={clsx('text-[14px]  font-[500] text-left', dark ? 'text-white' : 'text-blue10')}>{placeholder}</label>}
            <div className={clsx('relative', relativeClassName)}>
                {icon && <div className="absolute left-[16px] top-[10px] z-10">{icon}</div>}
                <input
                    ref={ref}
                    // eslint-disable-next-line jsx-a11y/no-autofocus
                    autoFocus={autoFocus}
                    readOnly={readOnly}
                    className={clsx(
                        'border-[1px]  w-full rounded-full text-[14px]  text-grey10 shadow-input read-only:bg-gray-100',
                        dark
                            ? 'border-white placeholder:text-white bg-transparent text-white'
                            : 'border-grey13 placeholder:text-grey10',
                        errored && '!border-red',
                        direction === 'left' && 'text-left px-4',
                        direction === 'center' && 'text-center',
                        direction === 'right' && 'text-right',
                        className,
                        icon ? 'py-2 px-[44px]' : 'p-2'
                    )}
                    type={type}
                    placeholder={inputPlaceholder}
                    autoComplete={autoComplete}
                    value={value}
                    defaultValue={defaultValue}
                    onChange={onChange}
                    name={name}
                />
            </div>
        </div>
    );
};

export default TextInput;
