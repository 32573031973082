import clsx from 'clsx';
import React, { useCallback, useEffect, useId, useMemo, useRef, useState } from 'react';
import { useWindowSize } from 'usehooks-ts';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import 'css.escape';
import { isServerSide } from '@/helpers/IsServerSide';

export interface ResponsiveType {
    [key: string]: {
        breakpoint: {
            max?: number;
            min: number;
        };
        items: number;
        partialVisibilityGutter?: number;
        paritialVisibilityGutter?: number;
        slidesToSlide?: number;
    };
}
export interface CarouselProps {
    className?: string;
    dotListClass?: string;
    itemClass?: string;
    arrowClass?: {
        left?: string;
        right?: string;
    };

    autoPlay?: boolean;
    autoPlayDelay?: number;
    pauseOnHover?: boolean;
    touchMoveSlideThreshold?: number;
    touchMaxOverDrag?: number;

    breakpoints: ResponsiveType

    showDots?: boolean;
    showDotsOutside?: boolean;
    showArrows?: boolean;

    children: React.ReactNode;
}

export function Carousel(props: CarouselProps) {
    const { children, breakpoints, className, itemClass, dotListClass, arrowClass: buttonClass,
        showDots, showArrows, pauseOnHover, autoPlay, autoPlayDelay = 3000, touchMoveSlideThreshold = 20, touchMaxOverDrag = 50, showDotsOutside = true } = props;
    const size = useWindowSize({ debounceDelay: 300, initializeWithValue: true });
    const id = useId();

    const debouncedWidth = size.width;
    const [canScrollPrev, setCanScrollPrev] = useState(false);
    const [canScrollNext, setCanScrollNext] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [dragOffset, setDragOffset] = useState(0);
    const autoPlayTimer = useRef<NodeJS.Timer>();
    const startX = useRef<number>(0);
    const isDragging = useRef<boolean>(false);

    const amountOfSlides = useMemo(() => {
        return Object.keys(breakpoints).map((item) => {
            const { breakpoint, items } = breakpoints[item];
            const { max, min } = breakpoint;

            const screenWidth = debouncedWidth;

            if (screenWidth >= min && screenWidth <= (max || Number.MAX_SAFE_INTEGER)) {
                return items;
            }
            return 1;
        }).sort().reverse().filter(Boolean)[0];
    }, [breakpoints, debouncedWidth]);

    const totalItems = React.Children.count(children);

    const dynamicStyles = useMemo(() => {
        const mainClass = `.carousel-${CSS.escape(id)}`;
        let styles = '';

        Object.keys(breakpoints).forEach((key) => {
            const { breakpoint, items } = breakpoints[key];
            const { min, max } = breakpoint;

            styles += `
                @media (min-width: ${min}px) ${max ? `and (max-width: ${max}px)` : ''} {
                    ${mainClass} .carousel-main {
                        width: calc(calc(100% * ${totalItems}) / ${items});
                        margin-left: ${items > 1 && totalItems < items ? `calc(calc(calc(100% / ${items}) / 2) * ${items - totalItems})` : '0'};
                    }
                    ${mainClass} .carousel-main .carousel-item {
                        width: calc(100% / ${totalItems});
                    }
                    ${mainClass} .carousel-dots {
                        display: ${totalItems - items < 1 ? 'none' : 'flex'};
                    }
                    ${mainClass} .carousel-dots-pushdown {
                        display: ${totalItems - items < 1 ? 'none' : 'flex'};
                    }
                    ${mainClass} .carousel-dots button:nth-of-type(1n+${(totalItems - items) + 2}) {
                        display: none;
                    }
                }
            `;
        });

        return `${styles}`;
    }, [breakpoints, totalItems, id]);

    const totalDots = totalItems - amountOfSlides < 1 ? 0 : (totalItems - amountOfSlides) + 1;

    const checkSlidePosition = useCallback((index: number) => {
        if (totalDots === 0) {
            setCanScrollPrev(false);
            setCanScrollNext(false);
            setCurrentIndex(0);
            return;
        }
        const newIndex = index > (totalDots - 1) ? totalDots - 1 : index;
        setCanScrollPrev(newIndex > 0);
        setCanScrollNext(newIndex < (totalDots - 1));
        if (index > (totalDots - 1)) {
            setCurrentIndex(newIndex);
        }
    }, [totalDots]);

    const stopAutoPlay = useCallback(() => {
        clearInterval(autoPlayTimer.current);
    }, []);

    const slideNext = useCallback((resetTimer = true) => {
        const newIndex = Math.min(currentIndex + 1, totalDots - 1);
        setCurrentIndex(newIndex);
        checkSlidePosition(newIndex);
        if (resetTimer) {
            stopAutoPlay();
        }
    }, [checkSlidePosition, currentIndex, stopAutoPlay, totalDots]);

    const slidePrev = useCallback((resetTimer = true) => {
        const newIndex = Math.max(currentIndex - 1, 0);
        setCurrentIndex(newIndex);
        checkSlidePosition(newIndex);
        if (resetTimer) {
            stopAutoPlay();
        }
    }, [checkSlidePosition, currentIndex, stopAutoPlay]);

    const goToIndex = useCallback((index: number, resetTimer = true) => {
        setCurrentIndex(index);
        checkSlidePosition(index);
        if (resetTimer) {
            stopAutoPlay();
        }
    }, [checkSlidePosition, stopAutoPlay]);

    const startAutoPlay = useCallback(() => {
        if (!autoPlay) {
            return;
        }
        stopAutoPlay();
        autoPlayTimer.current = setInterval(() => {
            if (canScrollNext) {
                slideNext(false);
            } else {
                goToIndex(0, false);
            }
        }, autoPlayDelay);
    }, [autoPlay, autoPlayDelay, canScrollNext, goToIndex, slideNext, stopAutoPlay]);

    const slideWidth = useMemo(() => {
        return isServerSide() ? 0 : document?.querySelector(`.carousel-${CSS.escape(id)} .carousel-item`)?.clientWidth || 0;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [totalDots, debouncedWidth, id]);

    const handleTouchStart = useCallback((e: React.TouchEvent) => {
        startX.current = e.touches[0].clientX;
        isDragging.current = true;
        stopAutoPlay();
    }, [stopAutoPlay]);

    const handleTouchMove = useCallback((e: React.TouchEvent) => {
        if (!isDragging.current) return;
        const currentX = e.touches[0].clientX;
        const deltaX = startX.current - currentX;

        // Allow dragging
        setDragOffset(deltaX * 0.9);
    }, []);

    const handleTouchEnd = useCallback(() => {
        if (!isDragging.current) return;
        isDragging.current = false;

        if (Math.abs(dragOffset) > touchMoveSlideThreshold) {
            const slidesToMove = Math.round((dragOffset) / slideWidth);
            if (!slideWidth || slidesToMove === 0) {
                if (dragOffset > touchMoveSlideThreshold) {
                    // Swiped left, go to next slide
                    slideNext(false);
                } else if (dragOffset < -touchMoveSlideThreshold) {
                    // Swiped right, go to previous slide
                    slidePrev(false);
                }
            } else {
                setCurrentIndex((prevIndex) => {
                    const newIndex = Math.min(Math.max(prevIndex + slidesToMove, 0), totalDots - 1);
                    checkSlidePosition(newIndex);
                    return newIndex;
                });
            }
        }

        // Reset drag offset
        setDragOffset(0);
        startAutoPlay();
    }, [dragOffset, slideNext, slidePrev, startAutoPlay, touchMoveSlideThreshold, checkSlidePosition, totalDots, slideWidth]);

    useEffect(() => {
        startAutoPlay();
        return () => stopAutoPlay();
    }, [autoPlay, autoPlayDelay, startAutoPlay, stopAutoPlay]);

    useEffect(() => {
        checkSlidePosition(currentIndex);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [totalDots]);

    const actualDragOffset = useMemo(() => {
        if (dragOffset > 0) {
            const maxOffset = (((totalItems - amountOfSlides) - currentIndex) * slideWidth) + touchMaxOverDrag;
            return Math.min(dragOffset, maxOffset);
        } if (dragOffset < 0) {
            const maxOffset = (currentIndex * slideWidth * -1) - touchMaxOverDrag;
            return Math.max(dragOffset, maxOffset);
        }
        return 0;
    }, [amountOfSlides, currentIndex, dragOffset, slideWidth, totalItems, touchMaxOverDrag]);

    return (
        <div className={clsx('relative', `carousel-${id}`)}>
            <style>{dynamicStyles}</style>
            {showArrows && canScrollPrev && (
                <div className={clsx('absolute left-[-2rem] top-0 bottom-0 m-auto hidden md:flex items-center justify-center z-10', buttonClass?.left)}>
                    <button
                        className="m-auto flex items-center justify-center"
                        type="button"
                        onClick={() => slidePrev()}
                    >
                        <ChevronLeftIcon className="text-grey3 w-[20px] h-[26px]" />
                    </button>
                </div>
            )}
            <div
                className={clsx('overflow-hidden relative w-full', className)}
                onMouseEnter={() => {
                    if (pauseOnHover) {
                        stopAutoPlay();
                    }
                }}
                onMouseLeave={() => {
                    if (pauseOnHover) {
                        startAutoPlay();
                    }
                }}
                onTouchStart={handleTouchStart}
                onTouchMove={handleTouchMove}
                onTouchEnd={handleTouchEnd}
            >
                <div
                    className={clsx('flex', 'carousel-main', { 'transition-transform ease-in-out': !isDragging.current })}
                    style={{
                        transitionDuration: isDragging.current ? '' : '400ms',
                        transform: `translateX(calc(-${(100 / totalItems) * currentIndex}% - ${actualDragOffset}px))`,
                    }}
                >
                    {React.Children.map(children, (child, index) => {
                        return (
                            <div
                                // eslint-disable-next-line react/no-array-index-key
                                key={index}
                                className={clsx(itemClass, 'flex-none', 'carousel-item')}
                            >
                                {child}
                            </div>
                        );
                    })}
                </div>
            </div>
            {showArrows && canScrollNext && (
                <div className={clsx('absolute right-[-2rem] top-0 bottom-0 m-auto hidden md:flex items-center justify-center z-10', buttonClass?.right)}>
                    <button
                        className="m-auto flex items-center justify-center"
                        type="button"
                        onClick={() => slideNext()}
                    >
                        <ChevronRightIcon className="text-grey3 w-[20px] h-[26px]" />
                    </button>
                </div>
            )}
            {showDots && (
                <>
                    {showDotsOutside && <div className={clsx('h-[50px]', 'carousel-dots-pushdown')} />}
                    <div className={clsx('absolute bottom-0 left-0 right-0 flex justify-center space-x-2 my-2', 'carousel-dots', dotListClass)}>
                        {Array.from({ length: totalItems }).map((_, index) => (
                            <button
                                // eslint-disable-next-line react/no-array-index-key
                                key={index}
                                onClick={() => goToIndex(index)}
                                className={`w-3 h-3 rounded-full ${currentIndex === index ? 'bg-main' : 'border-gray-400 border-[1px]'} focus:outline-none`}
                            />
                        ))}
                    </div>
                </>
            )}
        </div>
    );
}
